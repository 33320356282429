import phone from "../logos/phone.png";
import email from "../logos/email.png";
import code from "../logos/code.png";
import linkedin from "../logos/linkedin.png";

export default function firstSection({ handleCopy }) {
  return (
    <div
      className="section"
      style={{
        backgroundColor: "#e5f6ff",
        justifyContent: "center",
        opacity: 1,
      }}
    >
      <div className="content" style={{ maxWidth: "50rem" }}>
        <h1
          style={{
            marginBottom: "0",
          }}
        >
          LUCIANO VEIGA
        </h1>
        <p>05/20/2002</p>
        <h3>Computer Technician and Software Engineering Student</h3>
        <p>
          Knowledge in various areas related to computing. Interest in both
          hardware and software. Responsible and meticulous, always striving to
          complete tasks in the best and fastest way possible. Good
          communication skills in both Spanish and English.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <span
            className="logo"
            onClick={() => {
              handleCopy("phone");
            }}
          >
            <img src={phone} alt="phone" className="logo" />
          </span>
          <span
            className="logo"
            onClick={() => {
              handleCopy("email");
            }}
          >
            <img src={email} alt="email" className="logo" />
          </span>
          <a
            href="https://github.com/LucianoVeiga?tab=repositories"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={code} alt="github" className="logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/luciano-veiga/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="linkedin" className="logo" />
          </a>
        </div>
      </div>
    </div>
  );
}
