import "./App.css";
import React, { useState } from "react";

import TopNav from "./Components/topNav.js";
import FirstSection from "./Components/firstSection.js";
import SecondSection from "./Components/secondSection.js";
import ThirdSection from "./Components/thirdSection.js";
import Alert from "./Components/alert.js";

function App() {
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  let handleCopy = (button) => {
    switch (button) {
      case "phone":
        navigator.clipboard.writeText("1130186064");
        setAlertMessage("Phone number copied!");
        setAlertVisibility(true);
        setTimeout(() => {
          setAlertVisibility(false);
        }, 3000);
        break;
      case "email":
        navigator.clipboard.writeText("lucianoveigaa7@gmail.com");
        setAlertMessage("Email address copied!");
        setAlertVisibility(true);
        setTimeout(() => {
          setAlertVisibility(false);
        }, 3000);
        break;
      default:
        console.log("Fail to copy");
    }
  };

  return (
    <div className="App">
      {alertVisibility ? <Alert message={alertMessage} /> : null}
      <TopNav />
      <FirstSection {...{ handleCopy }} />
      <SecondSection />
      <ThirdSection />
    </div>
  );
}

export default App;
