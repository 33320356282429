export default function secondSection() {
  return (
    <div className="section" style={{ backgroundColor: "#e3e3e3" }}>
      <div className="content">
        <h1>LEARNING</h1>
        <div className="content" style={{ flexDirection: "row" }}>
          <div className="container">
            <h2>LANGUAGES</h2>
            <h5>ENGLISH</h5>
            <p>High level (B2), First Certificate in English exam.</p>
            <h5>SPANISH</h5>
            <p>Native language.</p>
          </div>
          <div className="container">
            <h2>KNOWLEDGES</h2>
            <h5>PROGRAMMING LANGUAGES</h5>
            <p>
              • Java • JavaScript • Python • C++ • Golang • HTML • PHP • CSS
            </p>
            <h5>TOOLS</h5>
            <p>
              • React • Angular • Google Firebase • Vue • Node.js • Watson
              Assistant • MySql Workbench • MongoDB • Github • Drive • Heroku •
              Github • Microsoft Office • Drive
            </p>
            <h5>SOFTWARE</h5>
            <p>
              • Sony Vegas Pro • Adobe Premiere Pro • Photoshop • Autocad •
              Revit
            </p>
            <h5>EXPERIENCES</h5>
            <p>
              • Experience in computer maintenance and installation, with
              knowledge in software security and cleaning. • Knowledge in web
              design and programming.
            </p>
          </div>
          <div className="container">
            <h2>EDUCATION</h2>
            <h5>INFORMATION SYSTEMS ENGINEERING STUDENT</h5>
            <p>National Technological University (UTN)</p>
            <h5>COMPUTER TECHNICIAN</h5>
            <p>Politécnico Modelo Institute 2015 - 2020</p>
            <h5>PRIMARY</h5>
            <p>Highest College School 2004 - 2014</p>
            <h5>ENGLISH</h5>
            <p>Bedford House Institute 2015-2019</p>
          </div>
        </div>
        <h1>WORK EXPERIENCE</h1>
        <div className="container">
          <h2>BOT EXPERT</h2>
          <h5>Snappy Commerce 2021 - present</h5>
          <p>
            Configuring virtual assistants for customer service, using Watson
            Assistant as a development tool. I successfully built an automation
            system within it for searching product categories and products using
            data in JSON format received through endpoints. I created similar
            workflows for various scenarios, contributed to the migration and
            restructuring of virtual assistants to a more professional
            environment, among other tasks.
          </p>
          <h2>VIDEO AND IMAGE EDITING</h2>
          <h5>MA Architecture, VGV Architects 2020 - 2021</h5>
          <p>
            Designing logos and banners for brands. Additionally, editing
            promotional and/or demonstration videos.
          </p>
          <h2>EMPLOYEE AT SKI RESORT, WASHINGTON, USA</h2>
          <h5>
            Crystal Mountain Resort, Alterra Mountain Company 2021 - 2022 | 2022
            - 2023
          </h5>
          <p>
            Constantly communicating in English with foreigners, working as a
            cashier in a fast-food restaurant located at a high point on the
            mountain, as well as a waiter, bartender, or support staff.
          </p>
          <h2>ASSEMBLING AND CLEANING COMPUTERS</h2>
          <h5>Lacroze Electricity 2021 - 2022</h5>
          <p>
            Aimed towards office systems, capable of performing various common
            tasks such as using browsers or Office, as well as
            higher-performance systems designed for rendering, editing in
            high-demand programs, or gaming.
          </p>
        </div>
      </div>
    </div>
  );
}
