export default function projectBox({
  link,
  previewImage,
  title,
  description,
  languages,
  repository,
}) {
  const languagesList = languages.map((language) => <li>{language}</li>);
  return (
    <a
      className="proyectbox"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="preview" src={previewImage} alt="previewImage"></img>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
        <ul>{languagesList}</ul>
        <a
          className="button"
          href={repository}
          target="_blank"
          rel="noopener noreferrer"
        >
          Github Repository
        </a>
      </div>
    </a>
  );
}
