import tournamentPreview from "../previews/tournament.png";
import drawingPreview from "../previews/drawing.png";
import ProjectBox from "./projectBox.js";

export default function thirdSection() {
  return (
    <div className="section" style={{ backgroundColor: "#f4fdffe5" }}>
      <div className="content">
        <h1>PROJECTS</h1>
        <p>Some small projects of mine...</p>
		{/* <ProjectBox
          {...{
            link: "https://drawing.lucianoveiga.ar/",
            previewImage: drawingPreview,
            title: "Drawing",
            description:
              "This project consists of a place where everyone can share their drawings or paintings online so other people can see them and rate them if they want to. Every person has their own personal account with their own 'my-posts' section.",
            languages: ["Next"],
            repository: "https://github.com/LucianoVeiga/Drawing",
          }}
        /> */}
        <ProjectBox
          {...{
            link: "https://tournament.lucianoveiga.ar/",
            previewImage: tournamentPreview,
            title: "Football Tournament",
            description:
              "In the following project people can prepare their tournament, uploading information about each team involved.",
            languages: ["React", "Golang"],
            repository: "https://github.com/LucianoVeiga/Tournament",
          }}
        />
		<p>This is all, for now...</p>
      </div>
    </div>
  );
}
